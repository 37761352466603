import { useState, useRef, useEffect } from "react"
import scrollIntoView from "scroll-into-view"
// import { isMobile } from "../../modules/utils"

export const STORY_JUMPING_BODY_CLASS = "story-jumping"

function useAutoScrollToStory(selected, noAutoScroll, firstLoad) {
  const [jumping, setJumping] = useState(false)
  const scrollTime = firstLoad ? 0 : 1000
  const selectedStoryRef = useRef()
  useEffect(() => {
    if (noAutoScroll) return // selected.pageType !== "default" ||
    // console.log("JUMP", selected, selectedStoryRef, noAutoScroll, scrollTime)
    setTimeout(() => {
      if (!selectedStoryRef.current) return
      setJumping(true)
      document.body.classList.add(STORY_JUMPING_BODY_CLASS)
      scrollIntoView(
        selectedStoryRef.current,
        {
          time: scrollTime,
          align: { top: 0, topOffset: 25 }, // isMobile() ? -100 : -100
        },
        () => {
          setJumping(false)
          setTimeout(
            () => document.body.classList.remove(STORY_JUMPING_BODY_CLASS),
            200
          )
        }
      )
    }, 0) // 50
  }, [selected, selectedStoryRef, noAutoScroll, scrollTime])
  return [selectedStoryRef, jumping]
}

export default useAutoScrollToStory
