import styled from "styled-components"

export const Wrapper = styled.div`
  &.section-nav {
    margin: 0 0 1em;
    padding-top: 3em; // 15px
    text-align: center;
    .navigation-text,
    .navigation-link {
      text-transform: uppercase;
      letter-spacing: 5px;
      color: #2a2b30;
    }
    .navigation-text {
      margin-right: 2em;
    }
    .navigation-link {
      &:hover {
        color: ${(p) => p.themeColor};
        text-decoration: none;
      }
      &.selected {
        font-weight: 700;
        text-decoration: none;
        // pointer-events: none;
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      .navigation-text {
        margin: 0;
      }
      .navigation-item {
        display: none;
      }
    }
  }

  &.story-nav {
    margin-top: 4em;
    margin-bottom: 4em;
    font-size: ${(p) => p.theme.fontSizeSmall};
    line-height: 1.8em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .navigation-link {
      color: black !important;
      border-bottom: 3px solid ${(p) => p.theme.dossierYellow};
      &:hover {
        background-color: rgba(
          ${(p) => p.theme.dossierYellow},
          ${(p) => p.theme.alphaUltraLight}
        );
      }
    }
    .navigation-text {
      width: 100%;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .selected {
      color: black;
      border: none !important;
      font-weight: 700;
    }
  }
  &.story-nav .navigation-item:not(:last-child)::after {
    color: black;
  }
`

export const NavItem = styled.span`
  &:not(:last-child)::after {
    display: inline;
    margin: 0 1em;
    // font-weight: 300;
    content: "|"; // •
    color: ${(p) => p.themeColor};
    font-weight: 700;
  }
`
