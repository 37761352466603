import React, { useRef, useState, useEffect, useContext } from "react"
import { useTransformer } from "../../modules/transformer"
import "./section.scss"
// import TermFreqTool from "../term-freq-tool/term-freq-tool"
import useChangeUrlWhenInView from "../stories/change-url-when-in-view"
import MetaInfo from "../meta-info/meta-info"
import { useInView } from "react-intersection-observer"
// import Compressor from "../compressor"

import { MethodsContext } from "../../../plugins/gatsby-source-dek-wp/components/term-freq-tool/methods-text"

import FurtherReadings from "../further-readings"
// import { useDonEncPageView, PAGE_BODY_CLASS } from "../app/donation-encourager"
import { PAGE_BODY_CLASS } from "../app/donation-encourager"
import {
  ExtraColumnWrapper,
  withAnnotations,
} from "gatsby-plugin-popup-preloader"
import { usePreview, MetaDataContext, withTermFreqTool } from "gatsby-source-dek-wp"
// import { ExtdTooltipWrapper } from "../tooltip/tooltip.style"
// import Tooltip, { ChartTooltipContext } from "../tooltip/tooltip"
// import { ContextPreview } from "../context-preview/context-preview"
import { SectionWrapper } from "./section.style"

const SCROLL_OFFSET_TOP = 20 // in percent

const Section = ({
  data,
  children,
  selected,
  furtherReadings,
  footerInView,
}) => {
  const metaData = useContext(MetaDataContext)
  const {
    restApi,
    suggestionApi,
    termApi,
    contextsApi,
    yearRange,
    shareProjectName,
    defaultMarkers,
  } = metaData

  const isStory = selected?.pageType === "default"
  const isFirstSection = selected?.orderId === 1 || isStory

  const {
    title,
    description,
    content,
    titleVideoPoster,
    titleVideoSource,
    postId,
    pageType,
    beginWithBody,
    bodyClass,
  } = data

  const contentState = usePreview(content, postId, restApi)
  const transformedContent = useTransformer(contentState)

  const bodyRef = useRef()

  const bodyTextRef = useChangeUrlWhenInView(
    data.slug,
    isFirstSection && !isStory
  )

  const [storiesRef, inView] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  useBodyClass(inView ? "stories-view" : "")

  const methodsState = useState(false)

  // useDonEncPageView(transformedContent)

  /* const chartTooltipState = useState([])
  const [tooltipPoints] = chartTooltipState
  const [extdTooltipPoints, setExtdTooltipPoints] = useState([])
  const onChartClick = useCallback(
    () => setExtdTooltipPoints(tooltipPoints),
    [tooltipPoints]
  )
  const [showContexts, setShowContexts] = useState(false)
  */

  const descriptionParsed = useTransformer(description)

  return (
    <MethodsContext.Provider value={methodsState}>
      <SectionWrapper className="section">
        <h1 className="section-title">{title}</h1>
        {pageType !== "about" && (
          <div className="section-teaser">{descriptionParsed}</div>
        )}
        <ExtraColumnWrapper>
          {titleVideoPoster && (
            <figure className="section-lead-image">
              <img src={titleVideoPoster} alt={title} />
              <figcaption>{titleVideoSource}</figcaption>
            </figure>
          )}
          <div
            ref={bodyRef}
            className={`section-body`}
            // style={{ minHeight: `${bodyMinHeight}px` }}
          >
            <div className="section-body-text" ref={bodyTextRef}>
              {pageType !== "about" && (
                <MetaInfo
                  data={isFirstSection ? {} : { ...data, titleVideoSource: "" }}
                  type="section"
                />
              )}
              <div
                className={`section-body-text-inner ${
                  isFirstSection ? "no-donation-encourager" : PAGE_BODY_CLASS
                }`}
              >
                {transformedContent}
              </div>
            </div>
            <div className={`section-children`} ref={storiesRef}>
              {isFirstSection && children}
            </div>
            {pageType !== "about" && (
              <FurtherReadings contents={furtherReadings} />
            )}
          </div>
        </ExtraColumnWrapper>
      </SectionWrapper>
    </MethodsContext.Provider>
  )
}

export default withTermFreqTool(withAnnotations(Section))

function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}

/**
 * 
                  <ExtdTooltipWrapper>
                    <Tooltip
                      points={extdTooltipPoints}
                      extended={true}
                      setShowContexts={setShowContexts}
                    />
                  </ExtdTooltipWrapper>



                  {!!showContexts && (
                    <ContextPreview
                      point={showContexts}
                      close={() => setShowContexts(false)}
                      contextsApi={contextsApi}
                    />
                  )}


                <ChartTooltipContext.Provider value={chartTooltipState}></ChartTooltipContext.Provider>
 */
