import React, { useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { StoryNav } from "../navigation"
import useAutoScrollToStory from "./auto-scroll-to-story"
import Story from "./story"
import JumpingContext from "../../contexts/jumping"

const Stories = ({
  stories,
  selected,
  noAutoScroll,
  additionalClass,
  firstLoad,
}) => {
  const lang = useContext(LangContext)
  const [selectedStoryRef, jumping] = useAutoScrollToStory(
    selected,
    noAutoScroll,
    firstLoad
  )
  return (
    <JumpingContext.Provider value={jumping}>
      <section className={`stories ${additionalClass}`}>
        {stories.map((s, i) => (
          <div key={`${i}_${lang.id}`}>
            <StoryNav contents={stories} selected={selected} />
            <Story
              key={i}
              id={s.id}
              data={s}
              jumping={jumping}
              storyRef={
                selected.pageType === "default" &&
                s.orderId === selected.orderId
                  ? selectedStoryRef
                  : undefined
              }
            />
          </div>
        ))}
      </section>
    </JumpingContext.Provider>
  )
}

export default Stories
