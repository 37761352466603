import React, { useContext } from "react"
import { Link } from "gatsby"
import { LangContext } from "gatsby-source-dek-wp"
import { useTransformer } from "../../modules/transformer"
import "./further-readings.scss"

const INTRO_STR = {
  de: "Lesen Sie auch",
  en: "Read also",
  ru: "Читайте также",
}

const FurtherReading = ({ href, title, teaser }) => {
  const transformedTeaser = useTransformer(teaser)
  return (
    <div className="further-reading">
      <div className="title">
        <Link to={href}>{title}</Link>
      </div>
      <div className="teaser">{transformedTeaser}</div>
    </div>
  )
}

const FurtherReadings = ({ contents }) => {
  const lang = useContext(LangContext)
  if (!contents || !contents.length) return null
  return (
    <>
      <div className="further-readings-divider" />
      <div className="further-readings">
        <div className="further-readings-intro">{INTRO_STR[lang.id]}:</div>
        {contents.map((c) => (
          <FurtherReading
            key={c.slug}
            href={`/${c.slug}`}
            title={c.title}
            teaser={c.description}
          />
        ))}
      </div>
    </>
  )
}

export default FurtherReadings
