import React from "react"
import FaqList from "./faq-list"
import FaqItem from "./faq-item"

export const faqTransformer = (node, i, { classList, children }) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-faq-item")) {
    const type = node.parent && node.parent?.attribs?.class === "wp-block-dekoder-custom-blocks-faq-list"
      ? "faq-item"
      : "collapse-box"
    return <FaqItem children={children} type={type} />
  }
  else if (classList.includes("wp-block-dekoder-custom-blocks-faq-list"))
    return <FaqList children={children} />
}
