import React, { useState, useMemo } from "react"
import { SectionNav } from "../navigation"
import Section from "../section"
import Stories from "../stories"
import {
  useAllContents,
  useContents,
  useLangs,
  Header,
  SiteLogo,
  LangSwitcher,
  useSpecialTeaser,
  withAuth,
  LangContext,
  withWebpCheck,
  MetaDataContext
} from "gatsby-source-dek-wp"
import useFirstLoad from "./first-load"
import "./app.scss"
import useMenu from "../menu"

import LogoFooter from "../logo-footer/logo-footer"
import { ThemeProvider } from "styled-components"
import { theme } from "./theme"

// import DonationEncourager from "donation-encourager"
// import useDonationEncourager, { PAGE_BODY_CLASS } from "./donation-encourager"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { useInView } from "react-intersection-observer"

import { useDeepCompareMemo } from "use-deep-compare"

function useEnrichedContents(contents, data) {
  const allPageContexts = data.allSitePage.nodes.map((n) => n.pageContext)
  const enrichedContents = useDeepCompareMemo(() => {
    return contents.map((c) => ({
      ...c,
      content:
        allPageContexts.find((pc) => pc.postId === c.postId)?.content ||
        c.content,
    }))
  }, [allPageContexts, contents])
  return enrichedContents
}

const App = ({ data, pageContext, location, metaData }) => {
  const { langId, slug } = pageContext
  const { restApi, categoryIds, categoryNames } = metaData
  const firstLoad = useFirstLoad()
  const allContents = useAllContents(data, false, categoryNames) // all all all
  const [lang, allLangs] = useLangs(langId, allContents, firstLoad)
  const [_contents, _selected] = useContents(allContents, lang.id, slug)

  const contents = useEnrichedContents(_contents, data)

  // useDonationEncourager(langId)

  const sections = useMemo(
    () => contents.filter((c) => c.pageType === "major"),
    [contents]
  )
  const stories = contents.filter((c) => c.pageType === "default")

  const selected = useMemo(
    () => (!_selected && sections.length ? sections[0] : _selected),
    [_selected, sections]
  )

  const isStory = selected?.pageType === "default"
  const isFirstSection = selected?.orderId === 1 || isStory

  const { metaInfos } = data.site.siteMetadata
  const { title } = metaInfos.find((m) => m.langId === lang.id)
  const [menu, menuButton, setMenuOpen] = useMenu(
    contents,
    selected,
    title
    // subtitle
  )

  const noAutoScroll = location && location.state && location.state.noAutoScroll

  // header
  const [expanded, setExpanded] = useState(false)
  const specialTeaser = useSpecialTeaser(data, lang.id)

  const [footerRef, footerInView] = useInView({
    threshold: 0,
    rootMargin: "0px 0px -60% 0px",
  })

  const sectionData = isFirstSection ? sections[0] : pageContext

  const patchedTheme = useMemo(
    () => ({
      ...theme,
      themeColor: metaData.themeColor,
    }),
    [theme]
  )

  return (
    <MetaDataContext.Provider value={metaData}>
      <LangContext.Provider value={lang}>
        <ThemeProvider theme={patchedTheme}>
          <Header expanded={expanded} contents={[] /*specialTeaser*/}>
            <SiteLogo
              logos={metaData.siteLogos}
              // onClick={() => setExpanded((e) => !e)}
            />
            <div style={{ display: "flex" }}>
              <LangSwitcher allLangs={allLangs} selected={selected} />
              {menuButton}
            </div>
          </Header>
          {menu}
          <div className="main-wrapper">
            {sections.length > 1 && (
              <SectionNav
                contents={sections}
                selected={selected}
                text={title}
                onTitleClick={() => setMenuOpen((o) => !o)}
              />
            )}
            <Section
              data={sectionData}
              selected={selected}
              furtherReadings={sections.filter((s) =>
                isFirstSection
                  ? s.postId !== sections[0].postId
                  : s.postId !== pageContext?.postId
              )}
              metaData={metaData}
              footerInView={footerInView}
            >
              {isFirstSection && (
                <Stories
                  stories={stories}
                  selected={selected}
                  noAutoScroll={noAutoScroll}
                  // additionalClass={PAGE_BODY_CLASS}
                  firstLoad={firstLoad}
                  restApi={restApi}
                />
              )}
            </Section>
            {pageContext.pageType !== "about" && (
              <SectionNav
                contents={contents.filter((c) => c.pageType === "about")}
                selected={selected}
                onTitleClick={() => setMenuOpen((o) => !o)}
              />
            )}
            <LogoFooter metaData={metaData} _ref={footerRef} />
          </div>
        </ThemeProvider>
      </LangContext.Provider>
    </MetaDataContext.Provider>
  )
}
// <DonationEncourager />

export default withWebpCheck(withAuth(App))
