// ids for putin special category in WP
exports.categoryIds = [401, 541, 27351]

exports.categoryNames = ["duma-de", "duma-ru", "duma-en"]

exports.metaInfos = [
  {
    langId: "de",
    title: "Duma",
    description: "27 Jahre, 7 Legislaturperioden, 385.000 Redebeiträge. dekoder hat gemeinsam mit der Novaya Gazeta die Sitzungs-Stenogramme der russischen Staatsduma durchforstet, um herauszufinden worüber und wie oft welche Abgeordneten seit 1994 gesprochen haben.",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2021/09/dumaspecial_social_de.png",
  },
  {
    langId: "en",
    title: "State Duma",
    description: "27 years, 7 convocations, 385,000 speeches. Dekoder and Novaya Gazeta went through all the transcripts of the State Duma and created a tool that shows what and how often deputies have been talking about since 1994.",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2021/09/dumaspecial_social_de.png",
  },
  {
    langId: "ru",
    title: "Госдума",
    description: "27 лет, 7 созывов, 385 тысяч выступлений. «декодер» и «Новая газета» прошлись по всем стенограммам Государственной Думы и создали инструмент, показывающий, о чем и как часто говорили депутаты начиная с 1994-го года.",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2021/09/dumaspecial_social_ru.png",
  }
]

exports.siteMetadata = {
  title: `Duma`,
  description: `Ein dekoder-Special`,
  siteUrl: `https://duma.dekoder.org`,
  author: `dekoder.org`
}

exports.placeholder = {
  de: "<p>Dieses Material ist noch nicht veröffentlicht.</p>",
  en: "<p>This text is not published yet.</p>",
  ru: "<p>Русская версия будет опубликована позже.</p>",
  uk: "<p>This text is not published yet.</p>"
}

exports.pathPrefix = ""
exports.faviconPath = `../shared/src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = "UA-66707787-10"

exports.customHook = (createPage, siteComponent, langIds) => {
  langIds.forEach(langId => {
    const path = `/embed/${langId}`
    console.log("TOOL_EMBED", path)
    createPage({
      path,
      component: require.resolve(
        `../shared/plugins/gatsby-source-dek-wp/components/term-freq-tool/term-freq-tool-standalone.js`
      ),
      context: { langId: langId, layout: "no-layout" }
    })
  })
}
