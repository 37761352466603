import React, { useMemo } from "react"
import { ALL_LANGS, LangContext, MetaDataContext } from "gatsby-source-dek-wp"
import { ThemeProvider } from "styled-components"
import { theme } from "../app/theme"

const LayoutStandalone = ({ pageContext, children, metaData }) => {
  const { langId } = pageContext
  const lang = ALL_LANGS.find((l) => l.id === langId)
  const patchedTheme = useMemo(
    () => ({
      ...theme,
      themeColor: metaData.themeColor,
    }),
    [theme]
  )
  return (
    <ThemeProvider theme={patchedTheme}>
      <LangContext.Provider value={lang}>
        <MetaDataContext.Provider value={metaData}>
          {children}
        </MetaDataContext.Provider>
      </LangContext.Provider>
    </ThemeProvider>
  )
}

export default LayoutStandalone
