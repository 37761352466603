import dekoderLogo from "../shared/src/images/logos/dekoder-white.svg"
import fsoLogoMenu from "../shared/src/images/logos/fso-menu.png"
// import fsoLogoFooter from "./src/images/logos/fso-footer.png"
import dekoderLogoBlack from "../shared/src/images/logos/dekoder-black.svg"
import russlandAnalysenLogo from "../shared/src/images/logos/ra-transparent.png"
// import radLogo from "../shared/src/images/logos/rad.png"
import sfbLogoEn from "../shared/src/images/logos/sfb-logo-en.png"
import sfbLogoDe from "../shared/src/images/logos/sfb-logo-de.png"
import novayaBlack from "../shared/src/images/logos/novaya-black.png"
import novayaWhite from "../shared/src/images/logos/novaya-white-crop.png"
import novayaMobile from "../shared/src/images/logos/novaya-n.png"

export {
  siteMetadata,
  metaInfos,
  restApi,
  categoryIds,
  categoryNames,
} from "./meta-data-node"

export const themeColor = "#129fc9"

export const suggestionApi =
  "https://stateduma.api.dekoder.org/suggestion"
export const termApi = "https://stateduma.api.dekoder.org/term"
export const contextsApi = "https://stateduma.api.dekoder.org:8094/contexts"
export const yearRange = [1994, 2021]
export const shareProjectName = "dumaspecial"
export function defaultMarkers(lang) {
  return [
    { value: 1994, legend: "I" },
    { value: 1996, legend: "II" },
    { value: 2000, legend: "III" },
    { value: 2003, legend: "IV" },
    { value: 2007, legend: "V" },
    { value: 2011, legend: "VI" },
    { value: 2016, legend: "VII" },
  ]
}


export const exampleTerms = {
  de: "иностранный агент:ausländischer agent,цифровизация:digitalisierung,навальный,сирия:syrien,контрсанкция:gegensanktion,импортозамещение:importsubstitution,здравоохранение:gesundheitswesen,гмо:genmanipulation,фальсификация:fälschung,косово:kosovo,сименс:siemens,скинхед:skinhead,импичмент:impeachment,бирюлево,альтернативная гражданская служба:zivildienst,крым:krim,хунта:junta&бандеровец,ваучерная приватизация:voucher-privatisierung,офшор:offshore,олигарх",
  ru: "иностранный агент,цифровизация,навальный,сирия,контрсанкция,импортозамещение,здравоохранение,гмо,фальсификация,вброс,косово,сименс,скинхед,импичмент,бирюлево,альтернативная гражданская служба,крым,хунта&бандеровец,ваучерная приватизация,офшор,олигарх",
  en: "иностранный агент:foreign agent,цифровизация:digitalisation,навальный:navalny,сирия:syria,контрсанкция:counter sanction,импортозамещение:import substitution,здравоохранение:public health,гмо:gmo,фальсификация:fraud,косово:kosovo,скинхед:skinhead,импичмент:impeachment,бирюлево,крым:crimea,хунта:junta&бандеровец,ваучерная приватизация:voucher privatisation,офшор:offshore,олигарх:oligarch"
}

export const siteLogos = [
  {
    src: dekoderLogo,
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    /*slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]"
    }*/
  },
  {
    src: novayaWhite,
    srcMobile: novayaMobile,
    url: {
      de: "https://www.novayagazeta.ru/",
      en: "https://www.novayagazeta.ru/",
      ru: "https://www.novayagazeta.ru/",
    },
    scale: 0.8,
  },
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию",
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://specials.dekoder.org/en",
      ru: "https://www.dekoder.org/ru",
    },
    logo: dekoderLogoBlack,
  },
  {
    name: {
      de: "Novaya Gazeta",
      en: "Novaya Gazeta",
      ru: "Новая Газета",
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.novayagazeta.ru/",
      en: "https://www.novayagazeta.ru/",
      ru: "https://www.novayagazeta.ru/",
    },
    logo: novayaBlack,
    logoWidth: 110,
  },
]

export const mediaPartners = [
  {
    name: {
      de: "Forschungsstelle Osteuropa an der Universität Bremen",
      en: "Research Centre for East European Studies at the University of Bremen",
      ru: "Исследовательский центр Восточной Европы при Бременском университете",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.forschungsstelle.uni-bremen.de/",
    logo: fsoLogoMenu,
    hideIn: ["ru"],
  },
  {
    name: {
      de: "Globale Entwicklungsdynamiken von Sozialpolitik (SFB 1342)",
      en: "Global Dynamics of Social Policy (CRC 1342)",
      ru: "Global Dynamics of Social Policy (CRC 1342)",
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.socialpolicydynamics.de",
      en: "https://www.socialpolicydynamics.de/en/",
      ru: "https://www.socialpolicydynamics.de/en/",
    },
    logo: {
      de: sfbLogoDe,
      en: sfbLogoEn,
      ru: sfbLogoEn,
    },
    logoWidth: 105,
    hideIn: ["ru"],
  },
  {
    name: {
      de: "Russland Analysen",
      en: "Russland Analysen",
      ru: "Russland Analysen",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.laender-analysen.de/russland/",
    logo: russlandAnalysenLogo,
    hideIn: ["ru"],
    logoWidth: 75,
  },
  /*{
    name: {
      de: "Russian Analytical Digest",
      en: "Russian Analytical Digest",
      ru: "Russian Analytical Digest"
    },
    text: { de: "", en: "", ru: "" },
    url: "https://css.ethz.ch/en/publications/rad.html",
    logo: radLogo
  }*/
]
