import React, { useMemo, cloneElement } from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { v4 as uuidv4 } from "uuid"

import {
  linkTransformer,
  annotationTransformer,
} from "gatsby-plugin-popup-preloader"

import {
  termFreqToolTransformer,
  termSetterTransformer,
} from "gatsby-source-dek-wp"

import { faqTransformer } from "../components/faq/faq.transformer"
import { galleryTransformer } from "../components/gallery/gallery.transformer"

const transformers = [
  termFreqToolTransformer,
  termSetterTransformer,
  linkTransformer,
  annotationTransformer,
  faqTransformer,
  galleryTransformer,
]

const options = {
  replace: transform,
  trim: true, // remove "\n" whitespace
}

export function transform(node) {
  // node, i
  if (node.name === "style") return <></> // null
  if (node.type !== "tag") return
  const classList = nodeClassList(node)
  const { style } = attributesToProps(node.attribs)
  const children = getChildren(node)

  for (let x = 0; x < transformers.length; x++) {
    const t = transformers[x]
    if (typeof t !== "function") {
      console.error("transformer is not a function", t)
      continue
    }
    const comp = t(node, 0, { classList, style, children })
    if (comp) return cloneElement(comp, { ...comp.props, key: uuidv4() })
  }
}

export function transformString(contentString = "") {
  return parse(contentString || "", options)
}

export function useTransformer(contentString) {
  const transformedContent = useMemo(
    () => transformString(contentString),
    [contentString]
  )
  return transformedContent
}

function getChildren(node) {
  const c = domToReact(node.children, options)
  return React.Children.toArray(c)
}

function nodeClassList(node) {
  if (!node.attribs || !node.attribs.class) return []
  return node.attribs.class.split(" ")
}
