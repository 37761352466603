import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
import MenuButton from "../menu-button"

import "./menu.scss"

const MenuSection = ({ pages, allSubPages, selected }) => (
  <ul className="menu-section">
    {pages.map((p, i) => {
      const subPages = allSubPages.filter(
        (sp) => String(sp.orderId).split(".")[0] === String(p.orderId)
      )
      return (
        <Fragment key={p.slug || i}>
          <li
            className={`menu-item${p.comingSoon ? " unpublished" : ""}${
              selected?.slug === p.slug ||
              String(selected?.id).split(".")[0] === String(p.id)
                ? " selected"
                : ""
            }`}
          >
            <Link to={`/${p.slug}`}>
              <div>{p.menuTitle || p.title}</div>
            </Link>
          </li>
          {!!subPages.length && (
            <span className="sub-items">
              {subPages.map((sp, j) => (
                <li
                  key={sp.slug || `${i}-${j}`}
                  className={`menu-item sub-item${
                    sp.comingSoon ? " unpublished" : ""
                  }${selected?.slug === sp.slug ? " selected" : ""}`}
                >
                  <Link to={`/${sp.slug}`}>
                    <div>{sp.menuTitle || sp.title}</div>
                  </Link>
                </li>
              ))}
            </span>
          )}
        </Fragment>
      )
    })}
  </ul>
)

const Menu = ({ contents, title, subTitle, selected }) => {
  const sections = contents.filter((c) => c.pageType === "major")
  const stories = contents.filter((c) => c.pageType === "default")
  const aboutPages = contents.filter((c) => c.pageType === "about")
  return (
    <div className="menu">
      <div className="menu-inner">
        <div className="menu-site-title">
          <div className="title">{title}</div>
          <div className="subtitle">{subTitle}</div>
        </div>
        {[sections, aboutPages].map((list, i) => (
          <MenuSection
            key={i}
            pages={list}
            allSubPages={stories}
            selected={selected}
          />
        ))}
      </div>
    </div>
  )
}

function useMenu(contents, selected, title, subTitle) {
  const [menuOpen, setMenuOpen] = useState(false)
  useBodyClass(menuOpen ? "menu-open" : "")
  useEffect(() => {
    setMenuOpen(false)
  }, [selected])
  const menuComponent = (
    <Menu
      contents={contents}
      title={title}
      subTitle={subTitle}
      selected={selected}
    />
  )
  const menuButtonComponent = (
    <MenuButton menuOpen={menuOpen} onClick={() => setMenuOpen((o) => !o)} />
  )
  return [menuComponent, menuButtonComponent, setMenuOpen]
}

export default useMenu

function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}
