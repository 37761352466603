// extracted by mini-css-extract-plugin
export var alphaLight = ".4";
export var alphaMinimal = ".07";
export var alphaUltraLight = ".1";
export var articleRed = "#c93324";
export var articleRedRgb = "#c93324";
export var bgDark = "#151518";
export var blockMarginBottom = "2em";
export var blockMarginLeftRight = "calc((100% - 1080px)/2.5)";
export var blockMarginLeftRightTablet = "calc((100% - 800px)/2.5)";
export var blockMaxWidth = "660px";
export var blockMaxWidthTablet = "500px";
export var breakpointMobile = "1000px";
export var breakpointTablet = "1350px";
export var dossierYellow = "#ffdb29";
export var fontSizeH0 = "3.815em";
export var fontSizeH1 = "3.052em";
export var fontSizeH2 = "2.441em";
export var fontSizeH3 = "1.953em";
export var fontSizeH4 = "1.563em";
export var fontSizeH5 = "1.25em";
export var fontSizeSmall = ".8em";
export var fontSizeStandard = "1em";
export var fontSizeSuperSmall = ".64em";
export var gnoseBlue = "#129fc9";
export var headerHeight = "50px";
export var menuColor = "#2a2b30";
export var termFreqToolMaxWidth = "910px";
export var titleColor = "rgba(18,159,201,.7)";
export var titleColorLight = "rgba(18,159,201,.4)";
export var titleColorRgb = "#129fc9";
export var titleColorSolid = "#129fc9";
export var titleColorText = "#fff";
export var titleColorUltraLight = "rgba(18,159,201,.1)";
export var toolSidePadding = "30px";
export var toolSidePaddingMobile = "0";