const { config } = require("@fortawesome/fontawesome-svg-core")

exports.onClientEntry = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false
}

exports.shouldUpdateScroll = ({ loadPageSync, pathname, routerProps }) => {
  const { noScrollUpdate } = routerProps.location.state || {}
  const page = loadPageSync(pathname)
  const { pageType } = page?.json?.pageContext || {}
  const isStory = pageType === "default"
  // do not update scroll position on story navigation
  return isStory || noScrollUpdate ? false : true
}
