import { useEffect, useRef } from "react"

export function getFormatedDate(dateString) {
  const date = new Date(dateString)
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  let yyyy = date.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }
  return dd + "." + mm + "." + yyyy
}

export function usePrevious(val) {
  const ref = useRef()
  useEffect(() => {
    ref.current = val
  }, [val])
  return ref.current
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
