import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react"
import { useTransformer, transformString } from "../../modules/transformer"
import { LangContext } from "gatsby-source-dek-wp"
import { ExtraColumnPortal } from "gatsby-plugin-popup-preloader"
import { getFormatedDate } from "../../modules/utils"

import "./meta-info.scss"

const authorPrefix = { de: "Text", en: "Text", ru: "Текст", ua: "Текст" }
const videoSourcePrefix = { de: "Video", en: "Video", ru: "Видео", ua: "Відео" }
const videoCutPrefix = {
  de: "Schnitt",
  en: "Video Editing",
  ru: "Монтаж",
  ua: "Монтаж",
}
const photoEditorPrefix = {
  de: "Bildredaktion",
  en: "Photo Editing",
  ru: "Photo Editing",
  ua: "Photo Editing",
}
const translatorPrefix = {
  de: "Übersetzung",
  en: "Translation",
  ru: "Перевод",
  ua: "Переклад",
}
const dataVisualizationPrefix = {
  de: "Datenvisualisierung",
  en: "Data visualisation",
  ru: "Визуализация данных",
  ua: "візуалізація даних",
}

const metaFields = [
  // { propName: "author", prefix: authorPrefix,  },
  { propName: "photoEditor", prefix: photoEditorPrefix },
  { propName: "translator", prefix: translatorPrefix },
  { propName: "titleVideoSource", prefix: videoSourcePrefix },
  { propName: "titleVideoCut", prefix: videoCutPrefix },
  { propName: "dataVisualization", prefix: dataVisualizationPrefix },
  { propName: "date", prefix: {}, transform: getFormatedDate },
]

const MetaInfo = ({ data, type }) => {
  const { authorImage, author, authorDescription, date } = data
  const authorRef = useRef()
  const [hover, setHover] = useState(false)
  const [authorExpanded, setAuthorExpanded] = useState(false)
  const lang = useContext(LangContext)
  const metaItems = metaFields
    .filter((f) => !!data[f.propName])
    .map((f, i) => (
      <span className="meta-item" key={i}>
        {f.prefix[lang.id] ? `${f.prefix[lang.id]}: ` : ""}
        {typeof f.transform === "function"
          ? f.transform(data[f.propName])
          : transformString(data[f.propName])}
      </span>
    ))

  const parsedDescription = useTransformer(authorDescription)
  const description = useMemo(
    () => <div className="author-description">{parsedDescription}</div>,
    [authorDescription]
  )

  const setActive = useCallback(() => setHover(true), [])
  const setInactive = useCallback(() => setHover(false), [])

  const collapse = useCallback(() => setAuthorExpanded(false), [])

  return (
    <>
      <div
        className={`meta-info ${type || ""}${
          !author && !date && !metaItems.length ? " no-items" : ""
        }`}
        ref={authorRef}
      >
        {authorImage && (
          <button
            // ref={authorRef}
            onClick={() => setAuthorExpanded((e) => !e)}
            className={`author-image${authorExpanded ? " active" : ""}${
              hover ? " hover" : ""
            }`}
          >
            <img src={authorImage} alt={author} />
          </button>
        )}
        <div className={`meta-items`}>
          {!!author && (
            <span className="meta-item">
              {authorPrefix[lang.id]}:{" "}
              <button
                onClick={() => setAuthorExpanded((e) => !e)}
                onMouseEnter={() => setHover(true)}
                onFocus={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onBlur={() => setHover(false)}
              >
                {transformString(author)}
              </button>
            </span>
          )}
          {metaItems}
        </div>
      </div>
      {authorExpanded && (
        <ExtraColumnPortal
          refEl={authorRef}
          type={`popup`}
          expanded={authorExpanded}
          hover={hover}
          collapse={collapse}
          onMouseOver={setActive}
          onFocus={setActive}
          onMouseOut={setInactive}
          onBlur={setInactive}
          options={{
            noTopShift: true,
          }}
        >
          {description}
        </ExtraColumnPortal>
      )}
    </>
  )
}

export default MetaInfo
