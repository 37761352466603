import React, { useEffect} from "react"
import { useInView } from "react-intersection-observer"

export const Gallery = ({ children, classList = [] }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "0px 0px -60% 0px",
  })
  useBodyClass(inView ? "on-gallery" : "")
  return (
    <figure ref={ref} className={classList.join(" ")}>
      {children}
    </figure>
  )
}

function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}