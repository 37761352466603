import React, { useContext } from "react"
import { Link } from "gatsby"
import { LangContext, MetaDataContext } from "gatsby-source-dek-wp"
import { NavItem, Wrapper } from "./navigation.style"

const STORY_NAV_TEXT = {
  de: "Springen Sie direkt zu den Stories",
  en: "Jump directly to the stories",
  ru: "Перейти прямо к тематическим блокам",
}

const Navigation = ({ contents, selected, type, text, onTitleClick }) => { 
  const { themeColor } = useContext(MetaDataContext) || "#129fc9"
  return (
  <Wrapper className={`navigation ${type}`} themeColor={themeColor}>
    {text ? (
      <button
        className="navigation-text"
        onClick={onTitleClick}
      >{`${text}`}</button>
    ) : (
      ""
    )}
    {contents
      .sort((a, b) => a.id - b.id)
      .map((a, i) => (
        <NavItem className="navigation-item" key={a.slug} themeColor={themeColor}>
          <Link
            className={`navigation-link ${
              a.slug === selected.slug ||
              (type === "section-nav" &&
                i === 0 &&
                selected.pageType === "default")
                ? "selected"
                : ""
            }`}
            to={`/${a.slug}`}
          >
            {a.menuTitle || a.title}
          </Link>
        </NavItem>
      ))}
  </Wrapper>
)}

export const SectionNav = (props) => {
  return (
    <Navigation
      {...props}
      type="section-nav"
    />
  )
}

export const StoryNav = (props) => {
  const lang = useContext(LangContext)
  return (
    <Navigation {...props} text={STORY_NAV_TEXT[lang.id]} type="story-nav" />
  )
}
