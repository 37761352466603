import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { Wrapper, Question, Answer } from "./faq-item.style"

const FaqItem = ({ children, initiallyExpanded = false, type }) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const faqQuestion = children[0]
  const faqAnswer = children.slice(1)
  return (
    <Wrapper className={`${expanded ? "expanded" : "collapsed"} ${type}`}>
      <Question
        className="faq-item-question-wrapper"
        onClick={() => setExpanded((e) => !e)}
      >
        {faqQuestion}
        <FontAwesomeIcon icon={faAngleRight} />
      </Question>
      <Answer className="faq-item-answer-wrapper">{faqAnswer}</Answer>
    </Wrapper>
  )
}

export default FaqItem
