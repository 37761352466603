import styled from "styled-components"
import {
  blockMaxWidth,
  blockMaxWidthTablet,
  blockMarginLeftRight,
  blockMarginLeftRightTablet,
  blockMarginBottom,
  breakpointTablet,
  breakpointMobile,
  fontSizeSuperSmall,
  bgDark,
} from "../app/globals.module.scss"


export const blockStyles = `
  max-width: ${blockMaxWidth};
  margin: 0 ${blockMarginLeftRight} ${blockMarginBottom};

  @media screen and (max-width: ${breakpointTablet}) {
    max-width: ${blockMaxWidthTablet};
    margin: 0 ${blockMarginLeftRightTablet} ${blockMarginBottom};
  }

  @media screen and (max-width: ${breakpointMobile}) {
    padding: 0 15px ${blockMarginBottom};
    margin: 0;
    max-width: none;
  }

  @media print {
    padding: 0 15px ${blockMarginBottom};
    margin: 0;
    max-width: none;
  }
`

export const SectionWrapper = styled.section`
  figure.wp-block-gallery {
    // z-index: 1000;
    margin: 2rem 0 !important;
    max-width: none !important;
    padding: 2rem 0;
    ul {
      display: flex;
      // justify-content: center
      margin: 0;
      padding: 0 10vw 0 10vw;
      @media screen and (max-width: ${breakpointMobile}) {
        padding: 0;
      }
      overflow-x: auto;
      scroll-snap-type: x proximity;
      scroll-behavior: smooth;
      list-style: none;

      li.blocks-gallery-item {
        figure {
          text-align: center;
          display: table;
          // border: 1px dashed red;
          margin: 2rem;
          img {
            display: block;
            max-width: 50vw;
            height: auto;
            max-height: 90vh;
            width: auto;
          }
          @media screen and (max-width: ${breakpointMobile}) {
            margin: 1rem;
            img {
              max-width: 65vw;
            }
          }
          figcaption {
            display: table-caption;
            caption-side: bottom;
            text-align: center;
            // border: 1px dashed red;

            margin-top: 1rem;
            font-size: ${fontSizeSuperSmall};
            color: rgb(180, 180, 180);
            a,
            a:hover,
            a:visited,
            a:active {
              color: rgb(180, 180, 180) !important;
            }
            font-family: "Roboto Condensed";
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
        }
        // max-width: 50vw;
      }
    }
    > figcaption.blocks-gallery-caption {
      ${blockStyles}
      font-size: ${fontSizeSuperSmall};
      padding-left: 0;
      margin-top: 0.5em;
      color: rgb(180, 180, 180);
      letter-spacing: 0.5px;
      text-transform: uppercase;
      a {
        // color: rgb(180, 180, 180) !important;
        // border: none !important;
        &:hover {
          background: none !important;
          // border-bottom: 0.2em solid ${articleRed} !important;
        }
      }
    }
    &.bg-dark {
      padding: 4rem 0;
      margin: 5rem 0 !important;
      background-color: ${bgDark}; // #202020; // #101010;
      box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
      figcaption {
        color: rgb(240, 240, 240);
      }
      li.blocks-gallery-item {
        img {
          box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
`
