import styled from "styled-components"
import {
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeH5,
  fontSizeStandard,
  fontSizeSmall,
  breakpointMobile,
  breakpointTablet,
} from "../app/globals.module.scss"
// import { blockStyles } from "../article/article.style"

export const Question = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // center
  width: 100%;
  padding: 1em 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  &::before {
    content: counter(custom-counter);
    display: block;
    align-self: flex-start;
    top: 0;
    font-weight: 700;
    font-family: "Roboto Condensed";
    font-size: ${fontSizeH2};
    line-height: 1;
    margin-right: .5em;
    // border: 1px dashed green;
    @media screen and (max-width: ${breakpointTablet}) {
      font-size: ${fontSizeH3};
    }
    @media screen and (max-width: ${breakpointMobile}) {
      font-size: ${fontSizeH4};
    }
  }

  h4 { // question
    font-size: ${fontSizeStandard};
    font-weight: 400; // 700;
    width: 100%;
    text-align: left;
    line-height: 1.4;
    margin: 0 !important;
    // border: 1px dashed green;
  }

  > svg {
    font-size: ${fontSizeH5};
    width: 1em;
    transition: 0.3s ease-in-out;
    margin-left: 1rem;
    // border: 1px dashed green;
  }

  @media print {
    svg {
      transform: rotate(90deg);
    }
  }

  .expanded & {
    h4 {
      font-weight: 700;
    }
    svg {
      transform: rotate(90deg);
    }
  }
`

export const Answer = styled.div`
  transition: 0.3s ease-in-out;
  overflow: hidden;

  > *:first-child {
    // margin-top: 1.5em;
  }
`

export const Wrapper = styled.div`
  @media screen {
    &.collapsed {
      .faq-item-answer-wrapper {
        max-height: 0 !important;
        padding-top: 0 !important;
      }
      h4 > strong {
        font-weight: 400 !important;
      }
    }
    &.expanded {
      .faq-item-answer-wrapper {
        margin-top: 1.5rem;
        padding-bottom: 2rem;
        max-height: 600px;
        overflow: auto;
        @media screen and (max-width: ${breakpointMobile}) {
          max-height: 1200px;
        }
      }
    }
  }

  &.faq-item {
    padding: 0;
    margin: 0;
    counter-increment: custom-counter;
    position: relative;
    // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  padding-bottom: 0 !important;
  &.collapse-box {
    background-color: rgb(240, 240, 240);
    margin: 3rem 0 !important;
    max-width: none !important;
    > ${Question} {
      max-width: 660px;
      // ${blockStyles}
      margin-bottom: 0 !important;
      h4, h4 strong {
        font-weight: 700 !important;
      }
    }
    > .faq-item-answer-wrapper {
      font-family: "Roboto Condensed" !important;
      > * {
        max-width: 660px;
        margin: 0 auto;
      }
      * {
        font-family: "Roboto Condensed" !important;
      }
    }
  }
`
