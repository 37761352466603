import { useEffect } from "react"
import { navigate } from "gatsby"
import { useInView } from "react-intersection-observer"
import { STORY_JUMPING_BODY_CLASS } from "./auto-scroll-to-story"

const SCROLL_OFFSET_TOP = 50 // in percent

function useChangeUrlWhenInView(slug, disabled) {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  useEffect(() => {
    if (!inView || disabled) return
    if (document.body.classList.contains(STORY_JUMPING_BODY_CLASS)) return
    navigate(`/${slug}`, {
      replace: true,
      state: { noAutoScroll: true, noScrollUpdate: true },
    })
  }, [inView, slug, disabled])
  return ref
}

export default useChangeUrlWhenInView
