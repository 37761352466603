import { useEffect, useRef } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { donEnc } from "donation-encourager"
import "./donation-encourager.scss"

export const PAGE_BODY_CLASS = "donenc-article-body"

const DON_ENC_SETTINGS = {
  targetSelector: `.${PAGE_BODY_CLASS}:not(.no-donation-encourager)`,
  crossStorageUrl:
    "https://www.dekoder.org/d/cross-storage-hub/cross-storage-hub.html",
  domObserverEnabled: true,
  twingleWidgetUrl:
    "https://spenden.twingle.de/dekoder-ggmbh/donation-encourager/tw5dcbf7742aac7/widget",
  strings: {
    credit: "",
    body: function (timeStr, contentsStr, amountStr, storage) {
      return storage.totalContents
        ? "Du hast bislang <strong>"
            .concat(contentsStr, "</strong> auf dekoder gelesen.* Was ")
            .concat(
              storage.totalContents === 1
                ? storage.readContents.gnose === 1
                  ? "ist sie"
                  : "ist er"
                : "sind sie",
              " dir wert? Vielleicht <strong>"
            )
            .concat(amountStr, "</strong>?")
        : ""
    },
    hooks: {
      onResetBtnClick: function () {
        trackCustomEvent({
          category: "donEnc",
          action: "donEnc__reset",
          nonInteraction: true,
        })
      },
      onUnlockBtnClick: function () {
        trackCustomEvent({
          category: "donEnc",
          action: "donEnc__unlock",
          nonInteraction: true,
        })
      },
      onCtaBtnClick: function () {
        trackCustomEvent({
          category: "donEnc",
          action: "donEnc__cta",
          nonInteraction: true,
        })
      },
      onDonationFinished: function () {
        trackCustomEvent({
          category: "donEnc",
          action: "donEnc__donationFinished",
          nonInteraction: true,
        })
      },
    },
  },
  itemSelectorSettings: {
    items: [
      { value: 2, icon: "☕️" },
      { value: 5, icon: "🍕" },
      { value: 7, icon: "🌹" },
      { value: 50, icon: "💰" },
    ],
  },
  intrusivenessLevels: [
    {
      // level 1
      contentThreshold: 0,
      boxSettings: [{ position: "bottom", expanded: true }],
      itemSelectorSettings: {
        preselectedItemsFilter: (item, i) => i === 0,
      },
    },
    {
      // level 2
      contentThreshold: 5,
      boxSettings: [
        { position: "middle", expanded: false },
        { position: "bottom", expanded: true },
      ],
      itemSelectorSettings: {
        preselectedItemsFilter: (item, i) => i === 1,
      },
    },
    {
      // level 3
      contentThreshold: 10,
      boxSettings: [
        { position: "middle", expanded: false },
        { position: "bottom", expanded: true },
      ],
      itemSelectorSettings: {
        preselectedItemsFilter: (item, i) => i === 2,
      },
      contentLockEnabled: false, // true
    },
  ],
}

export default function useDonationEncourager(langId) {
  useEffect(() => {
    donEnc("init", DON_ENC_SETTINGS)
  }, [])

  useEffect(() => {
    if (langId === "de") donEnc("enable")
    else donEnc("disable")
  }, [langId])
}

export function useDonEncPageView(selected) {
  const prevSelected = usePrevious(selected)
  useEffect(() => {
    if (!selected || prevSelected === selected) return
    donEnc("pageview", "special")
    // console.log("PAGE VIEW");
  }, [selected, prevSelected])
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    if (!value) return
    ref.current = value
  }, [value])
  return ref.current
}
