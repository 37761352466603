import React from "react"
import useChangeUrlWhenInView from "./change-url-when-in-view"
import { useTransformer } from "../../modules/transformer"
import MetaInfo from "../meta-info"
import { usePreloadedPopups } from "gatsby-plugin-popup-preloader"
import { usePreview } from "gatsby-source-dek-wp"
import "./story.scss"

const Story = ({ data, id, jumping, storyRef, restApi }) => {
  const { title, content, slug, postId, popups } = data
  usePreloadedPopups(popups)
  const contentState = usePreview(content, postId, restApi)
  const transformedContent = useTransformer(contentState)
  const ref = useChangeUrlWhenInView(slug, jumping)

  return (
    <article
      className="story"
      ref={ref}
      id={`story_${id}`}
      style={{ position: "relative" }}
    >
      <h1 className="story-title" ref={storyRef}>
        {title}
      </h1>
      <MetaInfo data={data} />
      <div className="story-body-text">
        {transformedContent}
      </div>
    </article>
  )
}

export default Story
