import { useRef } from "react"

function useFirstLoad() {
  const tmp = useRef()
  if (!tmp.current) {
    tmp.current = 1
    return true
  }
  return false
}

export default useFirstLoad
