import React, { useContext, useMemo } from "react"
import { TermFreqTool, withTermFreqTool } from "./term-freq-tool"
import "./term-freq-tool-standalone.scss"
import TermSetter from "./term-setter"
import { Header, LangContext, SiteLogo } from "gatsby-source-dek-wp"
import { enrichTermBundleWithColors } from "./term-colors"
import termsStringToTermBundles from "./term-setter/terms-string-to-term-bundles"
import { MetaDataContext } from "../../contexts/meta-data"

const StandaloneTool = () => {
  const lang = useContext(LangContext)

  const metaData = useContext(MetaDataContext)
  const {
    suggestionApi,
    termApi,
    yearRange,
    siteLogos,
    shareProjectName,
    exampleTerms,
    defaultMarkers,
  } = metaData

  const currExampleTerms = useMemo(() => {
    // if (lang.id !== "ru") return []
    const termBundles = termsStringToTermBundles(exampleTerms[lang.id])
    const coloredTerms = enrichTermBundleWithColors(termBundles)
    return coloredTerms
  }, [lang.id])

  console.log("STANDALONE", lang)

  return (
    <>
      <Header>
        <SiteLogo logos={siteLogos} />
      </Header>
      <TermFreqTool
        type="standalone"
        suggestionApi={suggestionApi}
        termApi={termApi}
        yearRange={yearRange}
        shareProjectName={shareProjectName}
        defaultMarkers={defaultMarkers}
      />
      <TermSetter terms={currExampleTerms} type="examples" />
    </>
  )
}

export default withTermFreqTool(StandaloneTool)
